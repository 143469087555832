.smallKPIContainer{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0px 6px 4px 0px rgba(0, 0, 0, 0.25);
    text-align: center;
    display: table;
}

.smallKPIBlueContainer{
    background: linear-gradient(180deg, #03194F 0%, rgba(0, 42, 96, 0.95) 5.73%, rgba(0, 59, 115, 0.91) 13.54%, rgba(0, 74, 134, 0.86) 27.08%, rgba(0, 91, 151, 0.78) 45.31%, rgba(0, 104, 169, 0.74) 56.77%, rgba(0, 120, 187, 0.67) 72.92%, rgba(0, 135, 203, 0.65) 79.69%, rgba(0, 151, 219, 0.62) 88.02%, rgba(0, 167, 238, 0.50) 100%);
}

.smallKPIYellowContainer{
    background: linear-gradient(180deg, #FD5F2D 0%, rgba(255, 109, 56, 0.95) 5.73%, rgba(255, 123, 67, 0.91) 13.54%, rgba(255, 137, 78, 0.86) 27.08%, rgba(255, 152, 89, 0.78) 45.31%, rgba(255, 168, 100, 0.74) 56.77%, rgba(255, 180, 111, 0.67) 72.92%, rgba(255, 194, 121, 0.65) 79.69%, rgba(255, 208, 129, 0.62) 88.02%, rgba(255, 223, 140, 0.50) 100%);
}

.smallKPIGreenContainer{
    background: linear-gradient(180deg, #001A48 0%, rgba(0, 42, 81, 0.95) 5.73%, rgba(0, 57, 91, 0.91) 13.54%, rgba(0, 75, 101, 0.86) 27.08%, rgba(0, 92, 111, 0.78) 45.31%, rgba(0, 107, 120, 0.74) 56.77%, rgba(0, 123, 130, 0.67) 72.92%, rgba(0, 138, 139, 0.65) 79.69%, rgba(0, 154, 149, 0.62) 88.02%, rgba(0, 169, 158, 0.50) 100%);
}

.smallKPIRedContainer{
    background: linear-gradient(180deg, #24093E 0%, rgba(62, 10, 66, 0.95) 5.73%, rgba(89, 13, 70, 0.91) 13.54%, rgba(114, 13, 73, 0.86) 27.08%, rgba(161, 17, 81, 0.78) 45.31%, rgba(185, 17, 84, 0.74) 56.77%, rgba(185, 17, 84, 0.67) 72.92%, rgba(208, 19, 86, 0.65) 79.69%, rgba(232, 19, 90, 0.62) 88.02%, rgba(254, 21, 93, 0.50) 100%);
}

.smallKPI{
    font-family: Roboto-Bold, sans-serif;
    font-size: 16px;
    color: white;
    display: table-cell;
    vertical-align: middle;
}