body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span {
  caret-color: transparent;
}

p {
  caret-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "Lato-Bold";   /*Can be any text*/
  src: local("Lato-Bold"),
    url("./Fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-Light";   /*Can be any text*/
  src: local("Lato-Light"),
    url("./Fonts/Lato-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Light";   /*Can be any text*/
  src: local("Roboto-Light"),
    url("./Fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";   /*Can be any text*/
  src: local("Roboto"),
    url("./Fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Bold";   /*Can be any text*/
  src: local("Roboto-Bold"),
    url("./Fonts/Roboto-Bold.ttf") format("truetype");
}