.headerContainer{
    position: absolute;
    background-color: white;
    height: 6%;
    padding: 0.7% 1% 0.2% 1.5%;
    box-sizing: border-box;
    width: 100%;
    z-index: 1;
}

.noCursor{
    cursor: none;
    caret-color: transparent;
}

.logoHeader{
    height: 100%;
    width: auto;
    position: relative;
    z-index: 2;
    bottom: 0.5vh;
    cursor: pointer;
}

.iconsHeaderContainer{
    position: relative;
    float: right;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
}

.iconHeaderContainer{
    cursor: pointer;
    z-index: 2;
    margin: 0% 0.5%;
    transition: 0s 0.1s;
}

.iconHeaderContainer:active{
    animation: tap-effect 0.3s forwards;
}

.iconHeader{
    color: #797979;
    font-size: 20px !important;
}

.iconHeader:hover{
    color: #bdbdbd;
    transform: scale(1.1);
}

.iconLogoutHeader{
    color: #797979;
    font-size: 20px !important;
}

.iconLogoutHeader:hover{
    color: #b30e2f;
}

@keyframes tap-effect {
	0% {
		transform: scale3d(0.3, 0.3, 1);
	}
    25% {
        transform: scale3d(0.9, 0.9, 1);
    }
    50%, 100% {
        transform: scale3d(1, 1, 1);
	}
}

@media (max-width: 1000px) {
    .logoHeader{
        height: 95%;
        top: 0;
        left: 2vw;
    }
}

@media (max-height: 750px) {
    .headerContainer{
        height: 8%;
    }
}

@media (max-width: 700px), (orientation:portrait){
    .logoHeader{
        height: 30px;
        left: 5vw;
        top: 0.5vh;
    }   
}

@media (max-width: 400px){
    .headerContainer{
        height: 5.5%;
    }

    .logoHeader{
        height: 20px;
        left: 5vw;
        top: 0.5vh;
    }   
}