.menuContainer{
    position: absolute;
    background: linear-gradient(180deg, #666666 52.84%, #4881A0 100%);
    box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.25);
    top: 6%;
    right: 0;
    z-index: 10;
    height: 94%;
    width: 20%;
}

.shadowMenuContainer{
    display: block;
    position: fixed;
    width: 100%;
    height: 94%;
    background-color: white;
    top: 6%;
    opacity: 0.5;
    z-index: 5;
}

.shadowMenuHide{
    display: none;
}

.orchestrationMenuContainer{
    width: 100%;
    height: 10%;
    z-index: 1;
}

.orchestrationMenuContainer:hover{
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
}

.orchestrationButtonContainer{
    padding: 0 !important;
    width: 100%;
    height: 100%;
    display: block !important;
}

.orchestrationTitleContainer{
    width: 100%;
    height: 35%;
    padding: 1.5% 5%;
    box-sizing: border-box;
}

.orchestrationTitle{
    font-family: Roboto-Bold, sans-serif;
    font-size: 16px;
    color: white;
    float: right;
}

.orchestrationDataContainer{
    width: 100%;
    height: 32px;
    padding-top: 4%;
}

.orchestrationCardsContainer{
    height: 32px;
    width: 65%;
    float: left;
    padding: 0% 5%;
    overflow: hidden;
}

.orchestrationCardContainer{
    height: 100%;
    width: 32px;
    float: left;
    padding: 0% 2.5%;
}

.orchestrationLogoContainer{
    height: 32px;
    float: right;
    padding: 0% 4% 0% 0%;
}

.orchestrationLogo{
    float: right;
}

.listMenuContainer{
    height: 90%;
    width: 100%;
    border-top: 1px solid white;
    overflow-y: auto;
}

.textMenu{
    color: white !important;
    text-align: right;
    margin-right: 10%;
    font-family: Roboto-Light, sans-serif !important;
}

@media (max-width: 1600px) {
    .orchestrationLogoContainer{
        padding: 0% 5% 0% 0%;
    }
}

@media (max-width: 1400px) {
    .orchestrationDataContainer{
        padding-top: 3%;
    }
    .orchestrationLogoContainer{
        padding: 0% 6% 0% 0%;
    }
    .orchestrationCardsContainer{
        padding: 0% 2.5% 0% 5%;
        width: 67.5%;
    }
    .orchestrationCardContainer{
        padding: 0% 3%;
    }
}

@media (max-width: 1000px) {
    .orchestrationTitle{
        font-size: 18px;
    }
    .orchestrationDataContainer{
        padding-top: 1%;
    }
    .orchestrationLogoContainer{
        padding: 0% 9% 0% 0%;
    }
    .orchestrationCardsContainer{
        width: 50%;
        padding: 0% 5% 0% 10%;
    }
    .orchestrationCardContainer{
        padding: 0% 2%;
    }
}

@media (max-width: 700px), (orientation:portrait){
    .orchestrationTitleContainer{
        padding: 0.5% 5% 0% 5%;
    }
    .orchestrationDataContainer{
        padding-top: 1%;
    }
    .orchestrationLogoContainer{
        padding: 0% 4% 0% 0%;
    }
    .orchestrationCardsContainer{
        padding: 0% 2.5% 0% 5%;
        width: 67.5%;
    }
    .orchestrationCardContainer{
        padding: 0% 4%;
    }
}